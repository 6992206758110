import { Pipe, PipeTransform } from '@angular/core';
import { StatusName } from "@app/core/const/roam-status.const";

@Pipe({
  standalone: true,
  name: 'statusName'
})
export class StatusNamePipe implements PipeTransform {

  transform(status: string | number): string {
    return StatusName[status.toString()] || status.toString();
  }

}
