import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontColorDirective } from "@app/shared/directives/text-directive/font-color.directive";
import { IconPipe } from "@app/shared/pipes/icon.pipe";
import { InlineSVGModule } from "ng-inline-svg-2";
import { StatusNamePipe } from "@app/shared/pipes/status/status-name.pipe";

@Component({
  selector: 'label-status',
  standalone: true,
  imports: [
    CommonModule, FontColorDirective, IconPipe,
    InlineSVGModule, StatusNamePipe],
  template:`
    <label class="align-center gap-5 d-inline-flex" appFontColor
           [isBgOpacity]="true"
           [size]="size"
           [status]="status">
      <span class="label" [ngClass]="size === 'md' ? 'font-18px' : 'font-14px'" >
            {{ status | statusName }}
      </span>
    </label>
  `,
})
export class LabelStatusComponent {

  @Input()
  public status!: string | number;

  @Input()
  public name!: string;

  @Input()
  public size: 'md' | 'default' = 'default'
}
